import { useDispatch, useSelector } from "react-redux";
import "./Home.css";
import economyicon from "../assets/imgs/undraw_investing_re_bov7 1.svg";
import freeicon from "../assets/imgs/undraw_share_link_re_54rx 1.svg";
import quickicon from "../assets/imgs/undraw_maker_launch_re_rq81 1.svg";
import bgbanner from "../assets/imgs/undraw_link_shortener_mvf6 1.svg";
import darkEconomyIcon from "../assets/imgs/dark_investing.svg";
import darkFreeIcon from "../assets/imgs/dark_share_link.svg";
import darkQuickIcon from "../assets/imgs/dark_launch.svg";
import darkBgbanner from "../assets/imgs/dark_link_shortener.svg";
import buffer from "../assets/imgs/undraw_buffer_wq43 1.svg";
import Footer from "../Bars/Footer";
import TopBar from "../Bars/TopBar";
import { useEffect, useState } from "react";
import { GetSiteDataBySiteName } from "../Api/Settings/SettingsSlice";
import Aos from "aos";
import "aos/dist/aos.css";
import React from "react";
import homegif from "../assets/imgs/shorturlgif.gif";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function Home() {
  const sitedata = useSelector((state) => state.settings.data);
  const dispatch = useDispatch();

  // Dark mode kontrolü
  const [isDarkMode, setIsDarkMode] = useState(false);

  const wwu = [
    {
      title: "Kolayca kaydol!",
      subTitle: `Hemen kaydolun ve kısalttığınız linklere basıldıkça para kazanın. Kayıt olurken numara vb. bilgilere ihtiyaç duymadan hızlıca kayıt olun ve kazanmaya başlayın`,
      iconUrl: isDarkMode ? darkQuickIcon : quickicon, // Dark mode ikon değişimi
    },
    {
      title: "Güvenilir ve Kolay!",
      subTitle: `+18 reklamların olmamasının yanı sıra link geçmek diğer sitelere göre daha kolay!`,
      iconUrl: isDarkMode ? darkFreeIcon : freeicon, // Dark mode ikon değişimi
    },
    {
      title: "Grafiklerle Kazancınızı Takip Edin!",
      subTitle: `Grafiklerle hızlıca linklerinizin durumunu takip edin ve kazancınızı nasıl daha iyi yükseltebileceğiniz hakkında fikir sahibi olun.`,
      iconUrl: isDarkMode ? darkEconomyIcon : economyicon, // Dark mode ikon değişimi
    },
  ];

  useEffect(() => {
    Aos.init({ duration: 2000 });
    dispatch(GetSiteDataBySiteName());

    const currentTheme = localStorage.getItem('theme');
    if (currentTheme === 'dark') {
      setIsDarkMode(true);
    }

    // Theme switcher için event listener
    const toggleSwitch = document.querySelector('.theme-switch input[type="checkbox"]');
    if (toggleSwitch) {
      toggleSwitch.addEventListener('change', (e) => {
        setIsDarkMode(e.target.checked);
        localStorage.setItem('theme', e.target.checked ? 'dark' : 'light');
      });
    }
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Linkamon | Ana Sayfa";
  }, []);

  const logined = Boolean(localStorage.getItem("logined"));

  return (
    <>
      <Helmet>
        <title>Linkamon | Ana Sayfa</title>
        <meta name="description" content="Linklerinizi kısaltın, kısalttığınız linklerden gelir elde edin." />
        <meta name="keywords" content="linkamon, link kısalt, link kısaltarak para kazan" />
      </Helmet>
      <TopBar />
      <div data-aos="fade-up" className="home">
        <div className="home-banner">
          <div className="home-banner-inner">
            <div className="frame-parent">
              <div className="link-shortener-parent">
                <div className="link-shortener">
                  <h1 className="link-ksaltn">Link kısalt,</h1>
                  <h1 className="para-kazann">Para Kazan!</h1>
                </div>
                <div className="linklerinizi-ksaltn-ksaltt">
                  Linklerinizi kısaltın, kısalttığınız linkeden gelir elde edin.
                </div>
              </div>
              {!logined && (
                <div className="login-barner-btn-parent">
                  <Link to="/login" className="login-barner-btn">Giriş Yap</Link>
                  <Link to="/register" className="register-barner-btn">Ücretsiz Kayıt Ol</Link>
                </div>
              )}
            </div>
          </div>
          <img
            className="undraw-link-shortener-mvf6-1-icon"
            loading="lazy"
            alt="Background Banner"
            src={isDarkMode ? darkBgbanner : bgbanner} // Dark mode banner değişimi
          />
        </div>

        <div data-aos="fade-up" className="wwu-cards-container">
          {wwu.map((item, index) => (
            <div key={index} className="wwu-card-center">
              <div className="tamamen- ücretsiz-wrapper">
                <h2 className="tamamen-ücretsiz">{item.title}</h2>
              </div>
              <div className="registration">
                <div className="balamak-ok-kolay">{item.subTitle}</div>
              </div>
              <img className="undraw-freelancer-re-irh4-1-icon" loading="lazy" alt="" src={item.iconUrl} />
            </div>
          ))}
        </div>

        <div data-aos="fade-up" className="stats">
          <section className="stats1">
            <div className="stats-parent">
              <div className="container">
                <b className="b">{sitedata?.AllUsersLenght}+</b>
              </div>
              <div className="sub-title">Yayıncı</div>
            </div>
            <div className="stats-parent">
              <div className="container">
                <b className="b">{sitedata?.AllLinksLenght}+</b>
              </div>
              <span className="sub-title">Kısaltılan Link</span>
            </div>
            <div className="stats-parent">
              <div className="container">
                <b className="b">{sitedata?.AllClicksLenght}+</b>
              </div>
              <div className="sub-title">Link Tıklanması</div>
            </div>
          </section>
        </div>

        <div data-aos="fade-up" className="gify-container">
          <div className="gify-card">
            <div className="gify-texts">
              <span className="gify-title">Kullanımı Kolay ve Anlaşılır</span>
              <span className="gify-sub-title">
                Link kısaltıp kayıt olmak çok kolay. Sadece mail adresi ile kayıt olun link kısaltıp kazancınızı ve görüntülenmelerinizi grafiklerle takip edin.
              </span>
            </div>
            <div className="gify-media">
              <img style={{ objectFit: "cover" }} className="gify-img" src={homegif} alt="gif" />
            </div>
          </div>
        </div>

        <div data-aos="fade-up" className="hw-cards-container">
          <div data-aos="fade-up" className="hw-cards">
            <div className="hw-card">
              <b className="hw-card-title">Hangi Ödeme Yöntemleri Var?</b>
              <span className="hw-card-sub">
                Ödemelerinizi hesabınıza tanımladığınız banka IBAN bilgisi ya da Papara numarası ile hesabınıza kolaylıkla çekebilirsiniz.
              </span>
            </div>
            <div className="hw-card">
              <b className="hw-card-title">Nasıl link kısaltabilirim?</b>
              <span className="hw-card-sub">
                Kayıt olduktan sonra link kısaltma sayfasına girip istediğiniz linki kısaltıp istediğiniz ismi verebilirsiniz.
              </span>
            </div>
            <div className="hw-card">
              <b className="hw-card-title">Reklam engelleyicisi ne tür sorunlar yaratır?</b>
              <span className="hw-card-sub">
                Reklam engelleyicisi kullanılırsa sayfa yüklenmez ve linkten herhangi bir şekilde gelir elde edemezsiniz.
              </span>
            </div>
          </div>
          {!logined && (
            <Link to="/register" className="register-hw-btn">Ücretsiz Kayıt Ol</Link>
          )}
        </div>

        <div data-aos="fade-up" className="gify-container">
          <div className="gify-card">
            <div className="gify-media">
              <img className="gify-img" src={buffer} alt="linklerine değer kat" />
            </div>
            <div className="gify-texts">
              <span className="gify-title">Linklerine Değer Kat!</span>
              <span className="gify-sub-title">
                Kolaylıkla kayıt olup linklerinizi değerlendirebilirsiniz. 1000 tıklanma başına {sitedata?.RevenuePerClick * 1000} &#8378; gelir elde edin!
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Home;
