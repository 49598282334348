import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetSiteDataBySiteName } from "../Api/Settings/SettingsSlice";
import doneicon from "../assets/icons/Done.svg";

const AdsComponent = () => {
  const sitedata = useSelector((state) => state.settings.data);
  const url = useSelector((state) => state.url.url);
  const dispatch = useDispatch();
  const [barWidth, setBarWidth] = useState(0);
  const [popUnderTriggered, setPopUnderTriggered] = useState(false);
  const [linkClicked, setLinkClicked] = useState(false); // İlk tıklama kontrolü

  // Reklamları sırasıyla yükleyin
  useEffect(() => {
    dispatch(GetSiteDataBySiteName());
    loadAdsterraAd(); // İlk reklamı yükleyin
    loadSocialBarAd(); // Social bar reklamını yükleyin
  }, [dispatch]);

  // Orta (Merkez) reklam yükleme fonksiyonu
  const loadAdsterraAd = () => {
    const adContainer = document.getElementById("ad-banner");
    if (adContainer) {
      adContainer.innerHTML = "";
      const script1 = document.createElement("script");
      script1.type = "text/javascript";
      script1.text = `
        atOptions = {
          'key' : '11ccea64ee77afe876febaf4cd491263',
          'format' : 'iframe',
          'height' : 250,
          'width' : 300,
          'params' : {}
        };
      `;
      adContainer.appendChild(script1);

      const script2 = document.createElement("script");
      script2.type = "text/javascript";
      script2.src = "//www.topcreativeformat.com/11ccea64ee77afe876febaf4cd491263/invoke.js";
      adContainer.appendChild(script2);

      // Orta reklam yüklendikten sonra sağ sidebar reklamı yükle
      script2.onload = loadRightSidebarAd;
    }
  };

  // Social bar reklamını yükleme fonksiyonu
  const loadSocialBarAd = () => {
    const socialBarContainer = document.createElement("script");
    socialBarContainer.type = "text/javascript";
    socialBarContainer.src = "//pl24369940.cpmrevenuegate.com/30/ad/c1/30adc1f705ba4dcb89350eba77bcece5.js";
    document.body.appendChild(socialBarContainer); // Social bar'ı sayfanın en altına ekler
  };

  // Sağ sidebar reklamını yükleyen fonksiyon
  const loadRightSidebarAd = () => {
    const adContainer = document.getElementById("right-sidebar-ad");
    if (adContainer) {
      adContainer.innerHTML = "";
      const script1 = document.createElement("script");
      script1.type = "text/javascript";
      script1.text = `
        atOptions = {
          'key' : '6c9bce33c86d2f28b7e9284942d82e7e',
          'format' : 'iframe',
          'height' : 600,
          'width' : 160,
          'params' : {}
        };
      `;
      adContainer.appendChild(script1);

      const script2 = document.createElement("script");
      script2.type = "text/javascript";
      script2.src = "//www.topcreativeformat.com/6c9bce33c86d2f28b7e9284942d82e7e/invoke.js";
      adContainer.appendChild(script2);

      script2.onload = loadLeftSidebarAd;
    }
  };

  // Sol sidebar reklamını yükleyen fonksiyon
  const loadLeftSidebarAd = () => {
    const adContainer = document.getElementById("left-sidebar-ad");
    if (adContainer) {
      adContainer.innerHTML = "";
      const script1 = document.createElement("script");
      script1.type = "text/javascript";
      script1.text = `
        atOptions = {
          'key' : '6c9bce33c86d2f28b7e9284942d82e7e',
          'format' : 'iframe',
          'height' : 600,
          'width' : 160,
          'params' : {}
        };
      `;
      adContainer.appendChild(script1);

      const script2 = document.createElement("script");
      script2.type = "text/javascript";
      script2.src = "//www.topcreativeformat.com/6c9bce33c86d2f28b7e9284942d82e7e/invoke.js";
      adContainer.appendChild(script2);

      script2.onload = loadFooterAd;
    }
  };

  // Footer reklamını yükleyen fonksiyon
  const loadFooterAd = () => {
    const adContainer = document.getElementById("footer-ad");
    if (adContainer) {
      adContainer.innerHTML = "";
      const script1 = document.createElement("script");
      script1.type = "text/javascript";
      script1.text = `
        atOptions = {
          'key' : '39275c774881dcc2079a6209451a5c60',
          'format' : 'iframe',
          'height' : 90,
          'width' : 728,
          'params' : {}
        };
      `;
      adContainer.appendChild(script1);

      const script2 = document.createElement("script");
      script2.type = "text/javascript";
      script2.src = "//www.topcreativeformat.com/39275c774881dcc2079a6209451a5c60/invoke.js";
      adContainer.appendChild(script2);
    }
  };

  const triggerDirectLink = () => {
    const directUrl = "https://www.cpmrevenuegate.com/wk9rvhq766?key=aa09670d4c6c06fb70be53e6981c4434";
    const directLinkWindow = window.open(directUrl, "_blank", "width=1,height=1,left=-1000,top=-1000");

    if (directLinkWindow) {
      directLinkWindow.blur(); // Kullanıcının dikkatini çekmemek için odak dışına çıkar
      window.focus(); // Kullanıcıyı mevcut sayfada tutmak için pencereyi odakta tutar
    }
  };

  const handleInterested = () => {
    setBarWidth(25);
    triggerDirectLink(); // Burada direct link açılacak
  };

  const handleNextAd = () => {
    loadAdsterraAd();
  };

  const handleGetinfo = () => {
    setBarWidth(50);
    triggerDirectLink();
  };

  const handleComplete = () => {
    setBarWidth(75);
    if (!popUnderTriggered) {
      triggerDirectLink();
      setPopUnderTriggered(true);
    }
  };

  // Linke Git Butonu için fonksiyon - İlk tıklama direct link, ikinci tıklama kısaltılmış linke yönlendirme
  const handleLinkClick = () => {
    if (!linkClicked) {
      window.location.href = url.OrginalUrl; // İlk tıklamada direct link
      setLinkClicked(true); // İlk tıklama yapıldı
    } else {
      window.location.href = url.ShortenedUrl; // İkinci tıklamada kısaltılmış link
    }
  };

  const progressBarContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    maxWidth: "500px",
    margin: "20px auto",
    position: "relative",
  };

  const progressBarStyle = {
    position: "absolute",
    top: "50%",
    left: "15px",
    height: "6px",
    width: "calc(100% - 30px)",
    backgroundColor: "lightgray",
    zIndex: "0",
  };

  const progressFillStyle = {
    height: "100%",
    backgroundColor: "#7215fc",
    transition: "width 0.4s ease",
    width: `${barWidth}%`, // Bu kısmı ekleyin
  };

  const progressStepStyle = {
    width: "30px",
    height: "30px",
    margin: "0 10px",
    borderRadius: "50%",
    backgroundColor: "white",
    border: "4px solid #7215fc",
    position: "relative",
    transition: "background-color 0.3s ease, transform 0.3s ease",
  };

  const progressStepCompletedStyle = {
    ...progressStepStyle,
    backgroundColor: "#7215fc",
    color: "white",
    transform: "scale(1.1)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const buttonStyle = {
    padding: "12px 30px",
    backgroundColor: "#7215fc",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    transition: "background-color 0.3s ease",
    whiteSpace: "pre-line", // Yazıların alt alta görünmesi için
    textAlign: "center", // Buton içindeki yazıların ortalanması için
  };

  const hiddenStyle = {
    display: "none",
  };

  return (
    <>
      {/* Sol Sidebar 160x600 reklam */}
      <div
        id="left-sidebar-ad"
        style={{
          width: "160px",
          position: "fixed",
          left: "0",
          top: "100px",
          zIndex: "10",
        }}
      ></div>

      {/* Merkez 300x250 reklam */}
      <div id="ad-banner" style={{ marginBottom: "20px", textAlign: "center" }}></div>

      {/* Sağ Sidebar 160x600 reklam */}
      <div
        id="right-sidebar-ad"
        style={{
          width: "160px",
          position: "fixed",
          right: "0",
          top: "100px",
          zIndex: "10",
        }}
      ></div>

      {/* Footer 728x90 reklam */}
      <div
        id="footer-ad"
        style={{
          clear: "both",
          textAlign: "center",
          marginTop: "20px",
          minHeight: "100px",
          position: "fixed",
          bottom: "0",
          width: "100%",
          zIndex: "10",
        }}
      ></div>

      {/* İlerleme Çubuğu ve Butonlar */}
      <div style={{ display: "flex", alignItems: "center", flexDirection: "column", gap: 12 }}>
        <div style={progressBarContainerStyle}>
          <div style={progressBarStyle}>
            <div style={progressFillStyle}></div>
          </div>
          <div style={barWidth >= 25 ? progressStepCompletedStyle : progressStepStyle}>
            {barWidth >= 25 && <img src={doneicon} alt="done" style={{ width: "16px", height: "16px" }} />}
          </div>
          <div style={barWidth >= 50 ? progressStepCompletedStyle : progressStepStyle}>
            {barWidth >= 50 && <img src={doneicon} alt="done" style={{ width: "16px", height: "16px" }} />}
          </div>
          <div style={barWidth >= 75 ? progressStepCompletedStyle : progressStepStyle}>
            {barWidth >= 75 && <img src={doneicon} alt="done" style={{ width: "16px", height: "16px" }} />}
          </div>
          <div style={barWidth === 100 ? progressStepCompletedStyle : progressStepStyle}>
            {barWidth === 100 && <img src={doneicon} alt="done" style={{ width: "16px", height: "16px" }} />}
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
          <button style={barWidth === 0 ? buttonStyle : hiddenStyle} id="notintersetedbtn" onClick={handleNextAd}>
            İlgimi{"\n"}Çekmedi
          </button>
          <button style={barWidth === 0 ? buttonStyle : hiddenStyle} id="tintersetedbtn" onClick={handleInterested}>
            İlgimi{"\n"}Çekti
          </button>
        </div>

        <button style={barWidth === 25 ? buttonStyle : hiddenStyle} onClick={handleGetinfo}>
          Detaylı{"\n"}Bilgi Al
        </button>

        <button style={barWidth === 50 ? buttonStyle : hiddenStyle} onClick={handleComplete}>
          Bu{"\n"}Aşamayı{"\n"}Bitirdim
        </button>

        <button style={barWidth === 75 ? buttonStyle : hiddenStyle} onClick={handleLinkClick}>
          Linke{"\n"}Git
        </button>
      </div>
    </>
  );
};

export default AdsComponent;
