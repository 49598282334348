
import React, { useEffect, useState } from "react";
import "./TopBar.css";
import logo from "../assets/logo.jpeg";
import userico from "../assets/icons/icons8-login-50 1.svg";
import signinico from "../assets/icons/icons8-user-48 1.svg";
import menuico from "../assets/icons/menu-ico.svg";
import { Link } from "react-router-dom";

function TopBar() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  const [isToggled, setToggled] = useState(true);
  const handleTogleMenu = () => {
    const linksMenu = document.getElementById("side-bar-options");
    setToggled(!isToggled);
    isToggled
      ? linksMenu.classList.add("side-bar-open")
      : linksMenu.classList.remove("side-bar-open");
  };

  const logined = Boolean(localStorage.getItem("logined"));

  // Dark mode toggle logic
  useEffect(() => {
    const toggleSwitch = document.querySelector('.theme-switch input[type="checkbox"]');
    const currentTheme = localStorage.getItem('theme');

    if (currentTheme === 'dark') {
        document.documentElement.setAttribute('data-theme', 'dark');
        toggleSwitch.checked = true;
    } else {
        document.documentElement.setAttribute('data-theme', 'light');
        toggleSwitch.checked = false;
    }

    toggleSwitch.addEventListener('change', (e) => {
        if (e.target.checked) {
            document.documentElement.setAttribute('data-theme', 'dark');
            localStorage.setItem('theme', 'dark');
            switchSVGMode(true);  // Switch to dark mode SVGs
        } else {
            document.documentElement.setAttribute('data-theme', 'light');
            localStorage.setItem('theme', 'light');
            switchSVGMode(false);  // Switch to light mode SVGs (original versions)
        }
    });
  }, []);

  // SVG switching logic for dark mode
  const switchSVGMode = (isDarkMode) => {
    const svgMappings = {
      "terms": "../assets/imgs/undraw_terms_re_6ak4.svg",
      "share_link": "../assets/imgs/undraw_share_link_re_54rx 1.svg",
      "questions": "../assets/imgs/undraw_questions_re_1fy7.svg",
      "portfolio_website": "../assets/imgs/undraw_portfolio_website_re_jsdd.svg",
      "personal_information": "../assets/imgs/undraw_personal_information_re_vw8a.svg",
      "launch": "../assets/imgs/undraw_maker_launch_re_rq81 1.svg",
      "linkshortener": "../assets/imgs/undraw_link_shortener_mvf6 1.svg",
      "investing": "../assets/imgs/undraw_investing_re_bov7 1.svg"
    };

    if (isDarkMode) {
      // Switch to dark versions of the SVGs
      svgMappings["terms"] = "/mnt/data/dark_terms.svg";
      svgMappings["share_link"] = "/mnt/data/dark_share_link.svg";
      svgMappings["questions"] = "/mnt/data/dark_questions.svg";
      svgMappings["portfolio_website"] = "/mnt/data/dark_portfolio_website.svg";
      svgMappings["personal_information"] = "/mnt/data/dark_personal_information.svg";
      svgMappings["launch"] = "/mnt/data/dark_launch.svg";
      svgMappings["linkshortener"] = "/mnt/data/dark_linkshortner.svg";
      svgMappings["investing"] = "/mnt/data/dark_investing.svg";
    }

    // Update the DOM elements with the correct SVG paths
    document.getElementById('terms-svg').src = svgMappings["terms"];
    document.getElementById('share-link-svg').src = svgMappings["share_link"];
    document.getElementById('questions-svg').src = svgMappings["questions"];
    document.getElementById('portfolio-website-svg').src = svgMappings["portfolio_website"];
    document.getElementById('personal-information-svg').src = svgMappings["personal_information"];
    document.getElementById('launch-svg').src = svgMappings["launch"];
    document.getElementById('linkshortener-svg').src = svgMappings["linkshortener"];
    document.getElementById('investing-svg').src = svgMappings["investing"];
  };

  return (
    <div className="top-bar-container">
      <div className="top-bar">
        <Link to="/" relative="path">
          <img className="logo-1-icon" loading="lazy" alt="" src={logo} />
        </Link>
        <div className="component-1">
          <div className="component-1-child" />
          <div className="component-1-item" />
          <div className="component-1-inner" />
        </div>
        <div className="top-bar-inner">
          <div className="frame-parent-top">
          {user && user.Role === "admin" && (
            <div className="deme-kantlar-container">
              <Link to="/controlpanel" className="deme-kantlar">
                Controlpanel
              </Link>
            </div>
          )}
            <div className="deme-kantlar-container">
              <Link to="/aboutus" className="deme-kantlar">
                Hakkımızda
              </Link>
            </div>
            <div className="deme-kantlar-container">
              <Link to="/paid" className="deme-kantlar">
                Ödeme Kanıtları
              </Link>
            </div>
            <div className="deme-kantlar-container">
              <Link to="/contactus" className="deme-kantlar">
                İletişim
              </Link>
            </div>
            <div className="button-bar">
              <button className="bar-btn">
                <img
                  className="icons8-login-50-1"
                  alt=""
                  src={logined ? signinico : userico}
                />
                <div className="giri-yap-wrapper">
                  <Link
                    className="giri-yap"
                    to={logined ? "/dashboard" : "/login"}
                    relative="path"
                  >
                    {logined ? "Dashboard" : "Giriş Yap"}
                  </Link>
                </div>
              </button>
              {!logined && (
                <Link to="/register" className="bar-btn1">
                  <img className="icons8-user-48-1" alt="" src={signinico} />
                  <div className="kayt-ol-wrapper">
                    <div className="kayt-ol">Kayıt Ol</div>
                  </div>
                </Link>
              )}
            </div>
          </div>
        </div>
        <button className="menu-btn" onClick={handleTogleMenu}>
          <img className="menu-ico" alt="menu" src={menuico} />
        </button>

        {/* Dark mode toggle button */}
        <div className="theme-switch-wrapper">
          <label className="theme-switch" htmlFor="checkbox">
            <input type="checkbox" id="checkbox" />
            <div className="slider round"></div>
          </label>
          <em>Karanlık Mod!</em>
        </div>

      </div>
    </div>
  );
}

export default TopBar;
