import React from 'react'

function NotFound() {
  return (
    <div style={{display:"grid", placeItems:"center"}}>
        <h1>
        Bulunamadı
        </h1>
    </div>
  )
}

export default NotFound